<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                :value="searchFields.email"
                @input="inputChange($event, 'email')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                :value="searchFields.mobile"
                @input="inputChange($event, 'mobile')"
                @keypress="isNumber($event)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Role</label>
            <v-select
              v-model="searchFields.role_ids"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roles"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'Users',
      resourceName: 'users',
      singularName: 'user',
      searchFields: {
        name: null,
        email: null,
        mobile: null,
        role_ids: null,
        status: null,
      },
      roles: [],
      statuses: [
        {
          id: 'active',
          name: 'Active',
        },
        {
          id: 'inactive',
          name: 'In-Active',
        },
      ],
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    loadRoles() {
      this.axios.get('/roles/list')
        .then(res => {
          this.roles = res.data.data
        })
    },
  },
  created() {
    this.loadRoles()
  },
}
</script>
